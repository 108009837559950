import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Link } from "react-scroll"

const HeaderMenu = styled.header`
  // border: 1px solid hotpink;
  width: 100%;
  height: auto;

  position: fixed;
  z-index: 7;

  &.scrolling {
    background-color: #fff1f0;
    background-color: #fedcda;
    // box-shadow: 0 10px 28px 0 rgb(0 0 0 / 5%);
    transition: all 0.3s ease-in-out;
  }

  padding: 0.7rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 834px) {
  }
`

const Logo = styled.div`
  // border: 1px solid lightpink;
  width: 15vw;

  position: relative;
  z-index: 1;

  h1 {
    // border: 1px solid lightpink;

    font-weight: 400;
    font-size: 48px;
    font-size: 2.5vw;
    line-height: 61px;
    line-height: 1.27;
    letter-spacing: 4.8px;
    letter-spacing: 0.1em;

    text-transform: uppercase;

    display: flex;
    align-items: center;

    span.vertical {
      // border: 1px solid lightblue;

      font-size: 22px;
      font-size: 1.145vw;
      line-height: 29px;
      line-height: 1.32;
      letter-spacing: 1.1px;
      letter-spacing: 0.05em;

      display: inline-block;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);

      height: 1em;
      margin-right: -0.2em;
    }
  }

  @media only screen and (max-width: 834px) {
    width: 60vw;

    h1 {
      font-size: 31px;
      line-height: 39px;
      letter-spacing: 3.1px;

      span.vertical {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.7px;
      }
    }
  }
`

const Links = styled.div`
  // border: 1px solid lightblue;
  width: auto;
  height: 100%;
  display: inline-block;

  @media only screen and (max-width: 834px) {
    display: none;
  }
`

const StyledScrollLink = styled(Link)`
  // border: 1px solid hotpink;
  display: inline-block;
  width: auto;
  height: 100%;
  padding: 0 1.5rem;

  text-align: center;

  font-size: 22px;
  font-size: 1.145vw;
  line-height: 29px;
  line-height: 1.32;
  letter-spacing: 2.39px;
  letter-spacing: 0.109em;
`

const ChatButton = styled.button`
  // border: 1px solid lightblue;

  @media only screen and (max-width: 834px) {
    display: none;
  }
`

const HamburgerButton = styled.button`
  display: none;

  @media only screen and (max-width: 834px) {
    // border: 1px solid lightblue;
    display: inline-block;

    height: 2.3rem;
    width: 2.3rem;

    background: transparent;
    border-radius: 0;
    outline: none;

    z-index: 1;

    // to remove global style
    &:hover {
      background-color: transparent;
      color: #54575a;
    }
  }
`

const HamburgerLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background: #54575a;
  border-radius: 5px;

  transition: all 0.3s ease-in-out;
`

const HamburgerTop = styled(HamburgerLine)`
  transform: translate(0px, 4px);

  &.close-top {
    transform: rotate(45deg);
  }
`

const HamburgerBottom = styled(HamburgerLine)`
  transform: translate(0px, -4px);

  &.close-bottom {
    transform: rotate(-45deg);
  }
`

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff1f0;

  transform: translateX(105%);
  transition: transform 0.4s ease;

  z-index: 0;

  &.show {
    transform: translateX(0);
    transition: transform 0.4s ease;
  }
`

const MobileLinks = styled.div`
  // border: 1px solid lightblue;
  width: 75%;
  height: 100%;

  margin-top: 15vh;

  display: flex;
  flex-direction: column;

  text-align: right;

  @media only screen and (min-width: 641px) and (max-width: 834px) {
    width: 85%;
  }
`

const MobileStyledScrollLink = styled(Link)`
  // border: 1px solid lightpink;

  font-size: 21px;
  line-height: 27px;
  letter-spacing: 2.28px;

  margin-bottom: 28px;
`

const YellowBubble = styled.div`
  background: #f8e340;
  border-radius: 50%;

  position: absolute;
  top: 57px;
  right: -251px;

  width: 503px;
  height: 489px;

  z-index: -1;
`

const Header = () => {
  const [headerClass, setHeaderClass] = useState("")
  const [showMobileMenu, toggleMobileMenu] = useState(false)

  const changeUrl = newUrl => {
    window.history.replaceState(null, "", `#${newUrl}`)
  }

  const listenScrollEvent = event => {
    if (window.scrollY < 80) {
      return setHeaderClass("")
    } else if (window.scrollY > 80) {
      return setHeaderClass("scrolling")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)

    return () => window.removeEventListener("scroll", listenScrollEvent)
  }, [])

  const listenClickEvent = event => {
    // console.log(event.target.classList.contains("mobile-menu-button"))
    if (event.target.classList.contains("mobile-menu-button")) {
      return toggleMobileMenu(!showMobileMenu)
    }
  }

  useEffect(() => {
    window.addEventListener("click", listenClickEvent)

    return () => window.removeEventListener("click", listenClickEvent)
  }, [showMobileMenu])

  return (
    <HeaderMenu className={headerClass}>
      <Link
        to="top"
        spy={true}
        smooth={true}
        onClick={() => {
          changeUrl("top")

          if (!!showMobileMenu) {
            toggleMobileMenu(!showMobileMenu)
          }
        }}
      >
        <Logo>
          <h1>
            <span className="vertical">The</span>
            <span>CC Shop</span>
          </h1>
        </Logo>
      </Link>

      <Links>
        <StyledScrollLink
          to="about"
          spy={true}
          smooth={true}
          onClick={() => changeUrl("about")}
        >
          About
        </StyledScrollLink>
        <StyledScrollLink
          to="services"
          spy={true}
          smooth={true}
          onClick={() => changeUrl("services")}
        >
          Services
        </StyledScrollLink>
        <StyledScrollLink
          to="whyccshop"
          spy={true}
          smooth={true}
          onClick={() => changeUrl("whyccshop")}
        >
          Why CC Shop
        </StyledScrollLink>
        <StyledScrollLink
          to="values"
          spy={true}
          smooth={true}
          onClick={() => changeUrl("values")}
        >
          Values
        </StyledScrollLink>
      </Links>

      <Link
        to="contact"
        spy={true}
        smooth={true}
        onClick={() => changeUrl("contact")}
      >
        <ChatButton>Chat with me!</ChatButton>
      </Link>

      <HamburgerButton className="mobile-menu-button">
        <div style={{ position: `relative` }} className="mobile-menu-button">
          <HamburgerTop
            className={`mobile-menu-button ${
              showMobileMenu ? "close-top" : ""
            }`}
          />
          <HamburgerBottom
            className={`mobile-menu-button ${
              showMobileMenu ? "close-bottom" : ""
            }`}
          />
        </div>
      </HamburgerButton>

      <MobileMenu className={`${showMobileMenu ? "show" : ""}`}>
        <YellowBubble />

        <MobileLinks>
          <MobileStyledScrollLink
            to="about"
            spy={true}
            smooth={true}
            onClick={() => {
              changeUrl("about")
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            About
          </MobileStyledScrollLink>

          <MobileStyledScrollLink
            to="services"
            spy={true}
            smooth={true}
            onClick={() => {
              changeUrl("services")
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            Services
          </MobileStyledScrollLink>

          <MobileStyledScrollLink
            to="whyccshop"
            spy={true}
            smooth={true}
            onClick={() => {
              changeUrl("whyccshop")
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            Why CC Shop
          </MobileStyledScrollLink>

          <MobileStyledScrollLink
            to="values"
            spy={true}
            smooth={true}
            onClick={() => {
              changeUrl("values")
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            Values
          </MobileStyledScrollLink>

          <Link
            to="contact"
            spy={true}
            smooth={true}
            onClick={() => {
              changeUrl("contact")
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            <button
              style={{
                alignSelf: `flex-end`,
                marginTop: `10px`,
                marginRight: `-10px`,
                width: `286px`,
                height: `70px`,
                fontSize: `22px`,
                lineHeight: `18.57px`,
                letterSpacing: `2.93px`,
              }}
            >
              Chat with me!
            </button>
          </Link>
        </MobileLinks>
      </MobileMenu>
    </HeaderMenu>
  )
}

export default Header
